export const fontFamily = 'Lato';

export const backgroundColor = '#F8F8F8';
export const fontColorMenu = '#F8F8F8';
export const titleFontColor = '#455A78';
export const menuIconsColor = 'white';
export const iconsColor = '#455A78';
export const fontColor = '#555555';
export const dropSelectorColor = '#3E4755';
export const disabledColor = '#00000061';
export const tabGradientColor = '#F8F8F8' //Adicionar cor ao Side Menu dependendo do cliente

export const loginColor = 'white';
export const logininputColor = 'white';
export const loginErrorColor = 'red';
export const loginTermosDeUsoColor = '#E7E7E7';
export const loginBoxBGColor = '#0C1C33';
export const loginBGColor = '#0C1C33';
export const loginButtonBgc = '#889DAE';
export const loginButtonColor = 'white';
export const loginBorder = 'none';

export const topMenuBoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)';

export const barChartColor = '#1B7EB6';
export const lineChartColors = [
  '#0F5177',
  '#59B6EA',
  '#000000',
  '#2F7716',
  '#4EAB2D',
  '#33FF55',
  '#26A69A',
  '#1EBC71',
  '#721D87',
  '#DB2AE3',
  '#B5148B',
  '#231EBC',
];

export const chartColors = [
  '#0F5177',
  '#1B7EB6',
  '#59B6EA',
  '#1D8A84',
  '#3CB6AE',
  '#55E3DB',
  '#4EAB2D',
  '#2F7716',
  '#721D87',
  '#450775',
];

export const chartLighterColors = [
  '#e1e7eb',
  '#e2ecf2',
  '#e8f2f7',
  '#e2edec',
  '#e5f2f1',
  '#e8f6f5',
  '#e7f0e4',
  '#e4ebe1',
  '#ebe2ed',
  '#ddcce1',
];

export const chartLightColors = [
  '#c9d6de',
  '#cbdfea',
  '#d8eaf5',
  '#cce2e0',
  '#d2eae9',
  '#d7f3f2',
  '#d6e8cf',
  '#cfdeca',
  '#ddcce1',
  '#d4c7dd',
];

export const defaultLegendColor = '#D9D9D9';

export const fontSizeTitle = '24px';
export const xsFontSize = '10px';
export const smFontSize = '12px';
export const mdFontSize = '16px';
export const lgFontSize = '20px';
export const xlFontSize = '28px';
export const lineHeight125 = '125%';

export const lineColor = '#bebebe';
export const bgcMain = '#183053';
export const bgcMenuDivider = '#183053';
export const bgcSecondary = '#FFFFFF';
export const borderRightMenu = '1px solid #183053';

export const orange = '#EF770C';
export const red = '#EF0C0C';
export const primaryColor = '#193493';
export const lightBlue = '#23B0DC';
export const positiveGreen = '#26A69A';
export const negativeRed = '#e73c3c';

export const tableCellColor = '#000000';
export const tableCellDarkerColor = '#000000';

export const borderRadius = '10px';

export const xsPadding = '8px';
export const smPadding = '16px';
export const mdPadding = '24px';
export const lgPadding = '32px';
export const xlPadding = '40px';

export const xsMargin = '8px';
export const smMargin = '16px';
export const mdMargin = '24px';
export const lgMargin = '32px';
export const xlMargin = '40px';

export const mobileS = '320px';
export const mobileM = '375px';
export const mobileL = '425px';
export const mobileXL = '490px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopL = '1440px';
export const desktop = '2560px';
